import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LEAD_API } from "../../Api";
import ProjectFormApi from "./ProjectService";
import quotesApi from "./QuotesService";
import invoiceApi from "./InvoiceService";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

async function invalidateTagsHelperLead(dispatch) {
  dispatch(ProjectFormApi.util.invalidateTags(["Project"]));
  dispatch(quotesApi.util.invalidateTags(["Quotes"]));
  dispatch(invoiceApi.util.invalidateTags(["Invoice"]));
}

const leadFormApi = createApi({
  reducerPath: "leadForm",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["Lead"],
  endpoints: (builder) => ({
    getLead: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: LEAD_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: LEAD_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["Lead"],
    }),
    getLeadById: builder.query({
      query: (id) => {
        return {
          url: `${LEAD_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["Lead"],
    }),
    addLead: builder.mutation({
      query: (payload) => ({
        url: LEAD_API,
        method: "POST",
        body: payload
      }),
      invalidatesTags: ["Lead"],
      async onQueryStarted(arg, { dispatch }) {
        invalidateTagsHelperLead(dispatch)
      }
    }),
    updateLead: builder.mutation({
      query: (payload) => {
        const { id, body } = payload;
        return {
          url: `${LEAD_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Lead"],
      async onQueryStarted(arg, { dispatch }) {
        invalidateTagsHelperLead(dispatch)
      }
    }),
    updateManyLead: builder.mutation({
      query: (payload) => {
        const { companyId, Leades } = payload;
        return {
          url: `${LEAD_API}/updateMany/${companyId}`,
          method: "PUT",
          body: Leades,
        };
      },
      invalidatesTags: ["Lead"],
    }),
    deleteLead: builder.mutation({
      query: (id) => ({
        url: `${LEAD_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Lead"],
      async onQueryStarted(arg, { dispatch }) {
        invalidateTagsHelperLead(dispatch)
      }
    }),
    addLeadAttachment: builder.mutation({
      query: (payload) => ({
        url: LEAD_API + '/leadAttachment',
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Lead"],
      async onQueryStarted(arg, { dispatch }) {
        invalidateTagsHelperLead(dispatch)
      }
    }),
    deleteLeadAttachment: builder.mutation({
      query: (id) => ({
        url: `${LEAD_API}/leadAttachment/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Lead"],
      async onQueryStarted(arg, { dispatch }) {
        invalidateTagsHelperLead(dispatch)
      }
    }),
    updateLeadAttachment: builder.mutation({
      query: (payload) => {
        const { id, body } = payload;
        return {
          url: `${LEAD_API}/leadAttachment/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Lead"],
      async onQueryStarted(arg, { dispatch }) {
        invalidateTagsHelperLead(dispatch)
      }
    }),

  }),
});

export const {
  useGetLeadQuery,
  useGetLeadByIdQuery,
  useAddLeadMutation,
  useUpdateLeadMutation,
  useDeleteLeadMutation,
  useUpdateManyLeadMutation,
  useAddLeadAttachmentMutation,
  useDeleteLeadAttachmentMutation,
  useUpdateLeadAttachmentMutation
} = leadFormApi;

export default leadFormApi;
